<template>
	<main
		v-if="website"
		:style="pageCSSVars"
		class="page"
		@click="handleGlobalClick"
	>
		<StickyTrigger v-if="languageBlocks.header.settings.isSticky" />
		<BlockHeader
			v-if="isHeaderVisible"
			v-bind="headerProps"
			@toggle-visibility="isNavOpen = !isNavOpen"
			@cart-click="handleCartClick"
		/>
		<!-- Simple blocks -->
		<BlockUser
			v-for="blockId of currentPageBlocks"
			:id="languageBlocks[blockId].htmlId || blockId"
			:key="blockId"
			:data="languageBlocks[blockId]"
			:blocks="languageBlocks"
			:lcp="lcp"
			:ecommerce-translations="ecommerceTranslations"
			:components="languageElements"
			:style="blockId === firstVisibleBlockId ? firstBlockCSSVars : null"
			:current-locale="currentLocale"
			:is-cart-visible="headerProps.isCartVisible"
		/>
		<CookieBanner
			v-if="website.meta.isCookieBarEnabled"
			:disclaimer="languageData.cookieBannerDisclaimer || ''"
			:accept-text="languageData.cookieBannerAcceptText"
			:decline-text="languageData.cookieBannerDeclineText"
		/>
		<EcommerceShoppingCartProviderUser
			v-if="isLocaleWithEcommerceItems"
			:ecommerce-translations="ecommerceTranslations"
			:language="language"
			:is-header-sticky="headerProps.isSticky"
			:is-nav-hidden="!isHeaderVisible"
			:current-page-ecommerce-blocks="currentPageEcommerceBlocks"
			:current-page-ecommerce-components="currentPageEcommerceComponents"
		/>
		<EcommerceModalRoot
			:ecommerce-translations="ecommerceTranslations"
			:language="language"
			:current-locale="currentLocale"
			:is-cart-visible="headerProps.isCartVisible"
		/>
	</main>
</template>

<script>
import {
	computed,
	defineComponent,
} from 'vue';
import {
	mapState,
	mapGetters,
	mapActions,
	useStore,
} from 'vuex';

import StickyTrigger from '@zyro-inc/site-modules/components/StickyTrigger.vue';
import { scrollToSection } from '@zyro-inc/site-modules/utils/scrollToSection';
import { getGridItemSize } from '@zyro-inc/site-modules/utils/getGridItemSize';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { useEcwidStore } from '@zyro-inc/site-modules/use/useEcwidStore';
import { isInPreviewIframe } from '@zyro-inc/site-modules/utils/isInPreviewIframe';

import CookieBanner from '@/components/CookieBanner.vue';
import BlockUser from '@/components/block/BlockUser.vue';
import EcommerceShoppingCartProviderUser from '@/components/ecommerce/EcommerceShoppingCartProviderUser.vue';
import EcommerceModalRoot from '@/components/ecommerce/modals/EcommerceModalRoot.vue';
import BlockHeader from '@zyro-inc/site-modules/components/blocks/header/BlockHeader.vue';
import { getHeaderProps } from '@zyro-inc/site-modules/components/blocks/header/getHeaderProps';
import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import { getEcwidPages } from '@zyro-inc/site-modules/utils/getters/getEcwidPages';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { getIsLocaleWithEcommerce } from '@zyro-inc/site-modules/utils/getters/getIsLocaleWithEcommerce';

import {
	SYSTEM_LOCALE,
	MEDIA_MOBILE_BREAKPOINT,
	ELEMENT_POSITION_KEY_MOBILE,
	ELEMENT_POSITION_KEY_DESKTOP,
} from '@zyro-inc/site-modules/constants';

const NUMBER_OF_LCP_IMAGES_TO_COMPARE = 2;

export default defineComponent({
	name: 'Page',

	components: {
		BlockHeader,
		BlockUser,
		CookieBanner,
		EcommerceShoppingCartProviderUser,
		EcommerceModalRoot,
		StickyTrigger,
	},

	setup() {
		const { state } = useStore();
		const pageCSSVars = computed(() => objectToCssVariables(state.website?.styles));
		const {
			openEcwidHomepage,
			openCart,
			ecwidCartItemCount,
		} = useEcwidStore();

		return {
			pageCSSVars,
			openEcwidHomepage,
			openCart,
			ecwidCartItemCount,
		};
	},

	data() {
		return {
			isNavOpen: false,
			currentLocale: SYSTEM_LOCALE,
		};
	},

	computed: {
		...mapState(['website']),
		...mapState('ecommerce', ['shoppingCartItems']),
		...mapGetters('ecommerce', ['isStoreTypeZyro']),
		isLocaleWithEcommerceItems() {
			return getIsLocaleWithEcommerce({
				blocks: this.languageBlocks,
				elements: this.languageElements,
			});
		},
		defaultLocale() {
			return this.website?.meta.defaultLocale ?? SYSTEM_LOCALE;
		},
		languageData() {
			return this.website.languages[this.currentLocale];
		},
		languagePages() {
			return this.languageData.pages ?? {};
		},
		languageBlocks() {
			return this.languageData.blocks ?? {};
		},
		languageElements() {
			return this.languageData.elements ?? {};
		},
		ecwidPages() {
			return getEcwidPages({
				pages: this.languagePages,
				blocks: this.languageBlocks,
			}) ?? {};
		},
		headerProps() {
			return getHeaderProps(this.website, this.website?.siteId, {
				currentLocale: this.currentLocale,
				currentPageId: this.currentPageId,
				isLogoOptimized: true,
				shoppingCartItems: this.shoppingCartItems,
				ecwidCartItemCount: this.ecwidCartItemCount,
				isOpen: this.isNavOpen,
			});
		},
		isHeaderVisible() {
			return !this.languageData.isNavHidden;
		},
		ecommerceTranslations() {
			if (!this.isStoreTypeZyro) {
				return {};
			}

			return this.website?.ecommerceShoppingCart?.translations ?? {};
		},
		language() {
			if (!this.isStoreTypeZyro) {
				return null;
			}

			return this.website?.ecommerceShoppingCart?.lang ?? 'en';
		},
		currentPageId() {
			return getPageIdFromPath({
				path: this.$route.path,
				siteData: this.website,
			});
		},
		currentPage() {
			return this.languagePages?.[this.currentPageId];
		},
		currentPageBlocks() {
			return this.currentPage ? [
				...this.currentPage.blocks,
				...this.pageBlocksSlotFooter,
			] : [];
		},
		currentPageBlockData() {
			return this.currentPageBlocks.map((id) => this.languageBlocks[id]);
		},
		currentPageEcommerceBlocks() {
			if (!this.isLocaleWithEcommerceItems) {
				return [];
			}

			return this.currentPageBlockData.filter((block) => [
				'BlockEcommerceProduct',
				'BlockEcommerceProductList',
			].includes(block.type));
		},
		currentPageEcommerceComponents() {
			if (!this.isLocaleWithEcommerceItems) {
				return [];
			}

			const allEcommerceComponents = Object.keys(this.languageElements)?.filter((id) => this.languageElements[id].type === 'GridEcommerceButton');

			return allEcommerceComponents.filter((id) => this.currentPageBlockData.some((data) => data.components?.includes(id)))
				.map((id) => this.languageElements[id]);
		},
		pageBlocksSlotFooter() {
			if (!this.currentPage || this.currentPage.footerSlotIsHidden) {
				return [];
			}

			const footerBlock = Object.keys(this.languageBlocks)
				.find((blockId) => this.languageBlocks[blockId].slot === 'footer');

			return footerBlock ? [footerBlock] : [];
		},
		firstVisibleBlockId() {
			const positionKey = window.innerWidth < MEDIA_MOBILE_BREAKPOINT ? ELEMENT_POSITION_KEY_MOBILE : ELEMENT_POSITION_KEY_DESKTOP;

			return this.currentPageBlocks.find(
				(blockId) => !this.languageBlocks[blockId][positionKey]?.isHidden,
			);
		},
		firstBlockCSSVars() {
			const { isTransparent } = this.languageBlocks.header.background ?? {};

			return {
				'--header-height': isTransparent ? `${this.website?.meta.headerHeight}px` : null,
			};
		},
		lcp() {
			const [firstBlockId] = this.currentPage?.blocks ?? [];

			if (this.languageBlocks?.[firstBlockId]?.background?.current === 'image') {
				return {
					type: 'block-background',
					id: firstBlockId,
				};
			}

			if (this.languageBlocks?.[firstBlockId]?.type === 'BlockBlogList') {
				return {
					type: 'block-blog-list',
					id: firstBlockId,
				};
			}

			if (this.languageBlocks?.[firstBlockId]?.type === 'BlockEcommerceProduct') {
				return {
					type: 'block-ecommerce-product',
					id: firstBlockId,
				};
			}

			if (this.languageBlocks?.[firstBlockId]?.type === 'BlockEcommerceProductList') {
				return {
					type: 'block-ecommerce-product-list',
					id: firstBlockId,
				};
			}

			// this should return [{ blockId, elementId }, { blockId, elementId }, ...]
			// because we need both blockId and elementId to get image size
			const allElementIds = this.currentPageBlocks
				.filter((blockId) => this.languageBlocks[blockId]?.components?.length > 0)
				.flatMap((blockId) => this.languageBlocks[blockId].components.map((elementId) => ({
					blockId,
					elementId,
				})));

			const firstImages = allElementIds
				.filter(({ elementId }) => this.languageElements[elementId]?.type === 'GridImage')
				.slice(0, NUMBER_OF_LCP_IMAGES_TO_COMPARE)
				.map(({
					blockId,
					elementId,
				}) => {
					const elementData = this.languageElements[elementId];
					// Check wether image has 'mobile' or 'desktop' width/height
					// If yes, use them to calculate LCP, otherwise it's grid and calculate size via `getGridItemSize`
					const sizeInLayout = elementData.mobile ?? elementData.desktop;

					const {
						width,
						height,
					} = sizeInLayout ?? getGridItemSize(
						this.languageBlocks[blockId],
						elementData.settings.styles.position,
					);

					return {
						elementId,
						imageRatio: height / width,
					};
				});

			if (firstImages.length === 0) {
				return {};
			}

			const largestImage = firstImages.reduce(
				(previous, current) => (current.imageRatio > previous.imageRatio ? current : previous),
			);

			return {
				type: 'grid-image',
				id: largestImage?.elementId,
			};
		},
	},

	// TODO: super hacky impmentation to make currentLanguage reactive
	// this commit should be reverted and 'currentLocale' state should be pulled from vuex
	watch: {
		$route: {
			handler(currentRoute) {
				this.isNavOpen = false;
				this.forceCurrentLocale(currentRoute.path);
			},
			immediate: true,
		},
		defaultLocale: {
			handler() {
				this.forceCurrentLocale(this.$route.path);
			},
		},
	},
	mounted() {
		this.scrollToHash(window.location.hash);
	},
	methods: {
		...mapActions('ecommerce', ['setShoppingCartOpen']),
		forceCurrentLocale(path) {
			const { locale } = getPathParams({
				path,
				defaultLocale: this.website?.meta.defaultLocale ?? SYSTEM_LOCALE,
				languages: this.website?.languages,
			});

			if (locale !== this.currentLocale) {
				this.currentLocale = locale;
			}
		},
		handleCartClick() {
			if (this.isLocaleWithEcommerceItems) {
				this.setShoppingCartOpen(true);
			}
		},
		redirectToThirdPartyLink(anchorElement) {
			const {
				target,
				href,
			} = anchorElement;
			const shouldOpenInNewTab = target === '_blank';
			const linkOpenMode = shouldOpenInNewTab || isInPreviewIframe ? '_blank' : '_self';

			window.open(href, linkOpenMode);
		},
		scrollToHash(hash) {
			if (!hash) {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});

				return;
			}

			scrollToSection(hash);
		},
		async handleGlobalClick(event) {
			const closestAnchor = event.target.closest('a');

			if (!closestAnchor) {
				return;
			}

			const {
				href,
				pathname,
				origin,
				hash,
				target,
				search,
			} = closestAnchor;

			if (!href) {
				return;
			}

			event.preventDefault();

			const isTargetThirdParty = window.location.origin !== origin;

			if (isTargetThirdParty) {
				this.redirectToThirdPartyLink(closestAnchor);

				return;
			}

			const targetPageId = getPageIdFromPath({
				path: pathname,
				siteData: this.website,
			});
			const isTargetPageCurrentPage = this.$route.path === pathname;
			const queryParams = new URLSearchParams(search);
			const targetEcwidPage = queryParams.get('store-page');
			const isTargetPageWithEcwidBlock = Object.keys(this.ecwidPages).includes(targetPageId);
			const isTargetPageEcwid = !!targetEcwidPage || isTargetPageWithEcwidBlock;
			const shouldOpenInNewTab = target === '_blank';
			const fullPath = href.replace(origin, '');

			if (isTargetPageCurrentPage) {
				this.scrollToHash(hash);
			}

			if (shouldOpenInNewTab && !isInPreviewIframe) {
				window.open(href, target);
			} else if (this.$route.fullPath !== fullPath) {
				await this.$router.push(fullPath);
				this.scrollToHash(hash);
			}

			if (!isTargetPageEcwid) {
				return;
			}

			if (!targetEcwidPage) {
				if (!window.Ecwid) {
					return;
				}

				this.openEcwidHomepage();

				return;
			}

			if (targetEcwidPage === 'cart') {
				this.openCart();

				return;
			}

			// Todo: Ecwid page click handling needs refactor, window.location.reload isn't working on previews
			window.location.reload();
		},
	},
});
</script>

<style lang="scss">
.page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
</style>
