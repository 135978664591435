<template>
	<Component
		:is="openEcommerceModalComponent"
		v-if="openEcommerceModalComponent"
		:translations="ecommerceTranslations"
		:language="language"
		:current-locale="currentLocale"
		:is-cart-visible="isCartVisible"
		is-quick-preview
	/>
</template>

<script>
import {
	defineComponent,
	onMounted,
} from 'vue';
import { useRoute } from 'vue-router';

import EcommerceBookingEventSelect from '@/components/ecommerce/modals/EcommerceBookingEventSelect.vue';
import EcommerceBookingUnavailable from '@/components/ecommerce/modals/EcommerceBookingUnavailable.vue';
import EcommerceCheckoutFailed from '@/components/ecommerce/modals/EcommerceCheckoutFailed.vue';
import EcommerceCheckoutSuccess from '@/components/ecommerce/modals/EcommerceCheckoutSuccess.vue';
import EcommerceMessageButtonDisabled from '@/components/ecommerce/modals/EcommerceMessageButtonDisabled.vue';
import EcommerceOutOfStock from '@/components/ecommerce/modals/EcommerceOutOfStock.vue';
import { useEcommerceModal } from '@/components/ecommerce/modals/useEcommerceModal';

const OPEN_MODAL_QUERY_PARAM = 'open-modal';

export default defineComponent({
	components: {
		EcommerceBookingEventSelect,
		EcommerceBookingUnavailable,
		EcommerceCheckoutFailed,
		EcommerceCheckoutSuccess,
		EcommerceMessageButtonDisabled,
		EcommerceOutOfStock,
	},

	props: {
		ecommerceTranslations: {
			type: Object,
			default: () => ({}),
		},
		language: {
			type: String,
			default: 'en',
		},
		currentLocale: {
			type: String,
			required: true,
		},
		isCartVisible: {
			type: Boolean,
			default: false,
		},
	},

	setup() {
		const {
			openEcommerceModal,
			closeEcommerceModal,
			openEcommerceModalComponent,
		} = useEcommerceModal();
		const route = useRoute();

		onMounted(() => {
			const queryModalName = route.query[OPEN_MODAL_QUERY_PARAM];

			if (queryModalName) {
				openEcommerceModal(queryModalName);
			}
		});

		return {
			openEcommerceModal,
			closeEcommerceModal,
			openEcommerceModalComponent,
		};
	},

});
</script>
