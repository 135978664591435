<template>
	<ModalLayout
		v-if="isEcommerceStoreCreated"
		ref="modal"
	>
		<ModalContent
			:title="modalContent.title"
			:text="modalContent.text"
			:button-text="modalContent.buttonText"
			:data-qa-modal-type="modalContent.modalType"
			@handle-close="$refs.modal.handleCloseModalAction()"
		/>
	</ModalLayout>
</template>

<script>
import {
	mapActions,
	mapGetters,
} from 'vuex';

import {
	PRODUCT_TYPE_DONATION,
	PRODUCT_TYPE_BOOKING,
} from '@zyro-inc/site-modules/constants/ecommerce';
import ModalContent from '@/components/ecommerce/modals/partials/ModalContent.vue';
import ModalLayout from '@/components/ecommerce/modals/partials/ModalLayout.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		ModalLayout,
		ModalContent,
	},

	props: {
		translations: {
			type: Object,
			default: () => {},
		},
	},

	computed: {
		...mapGetters('ecommerce', ['isEcommerceStoreCreated']),
		isProductTypeDonation() {
			return this.$route.query.product === PRODUCT_TYPE_DONATION;
		},
		isProductTypeBooking() {
			return this.$route.query.product === PRODUCT_TYPE_BOOKING;
		},
		modalContent() {
			if (this.isProductTypeBooking) {
				return {
					title: this.translations.checkoutModalThanksForOrder,
					text: this.translations.checkoutModalSuccessfullyBooked,
					buttonText: this.translations.gotIt,
					modalType: 'success-orderbooking',
				};
			}

			if (this.isProductTypeDonation) {
				return {
					title: this.translations.checkoutModalThanksForDonation,
					text: this.translations.checkoutModalDonationReceived,
					buttonText: this.translations.gotIt,
					modalType: 'success-orderdonation',
				};
			}

			return {
				title: this.translations.checkoutModalThanksForOrder,
				text: this.translations.checkoutModalOrderReceived,
				buttonText: this.translations.gotIt,
				modalType: 'success-order',
			};
		},
	},

	created() {
		this.setShoppingCartItems([]);
	},

	methods: {
		...mapActions('ecommerce', ['setShoppingCartItems']),
	},
});
</script>
