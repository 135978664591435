import * as Vuex from 'vuex';

import ecommerce from './ecommerce';

export const storeConfig = {
	modules: {
		ecommerce,
	},
	state: {
		website: null,
	},
	getters: {},
	actions: {},
	mutations: {
		setWebsite: (state, { website }) => {
			state.website = website;
		},
	},
};
export default Vuex.createStore(storeConfig);
