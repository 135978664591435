import {
	isAppPrerendering,
	isAppHydrating,
} from '@zyro-inc/site-modules/utils/prerenderingFlags';
import { addCustomElements } from '@zyro-inc/site-modules/utils/customElements';
import { bustImageUrlCache } from '@/utils/bustImageUrlCache';

import {
	FONT_TYPE_CUSTOM,
	FONT_TYPE_GOOGLE,
	addCustomFontsFontFaces,
	constructGoogleFontsHref,
} from '@zyro-inc/site-modules/utils/font';
import {
	PRECONNECT_ECWID,
	PRECONNECT_YOUTUBE,
	PRECONNECT_VIMEO,
	PRECONNECT_MAP,
	PRECONNECT_INSTAGRAM,
} from '@/components/metas/constants';
import { getHeadData } from '@/components/metas/getHeadData';
import { getIntegrationElements } from '@/utils/getIntegrationElements';
import {
	addElementToBody,
	addElementToHead,
	ELEMENT_DATA_ATTRIBUTE,
} from '@zyro-inc/site-modules/utils/addDomElements';

const BODY_ELEMENTS_BY_ELEMENT_ID = [
	'noscript-gtm',
	'fb-messenger',
];

const addElementsToDom = (elements) => {
	elements.forEach((element) => {
		if (BODY_ELEMENTS_BY_ELEMENT_ID.includes(element.properties[ELEMENT_DATA_ATTRIBUTE])) {
			return addElementToBody(element);
		}

		return addElementToHead(element);
	});
};

const getPreconnectLinkElements = ({
	hasEcwid,
	hasYoutube,
	hasVimeo,
	hasMap,
	hasInstagram,
}) => {
	const preconnectLinks = [
		import.meta.env.VITE_ASSETS_ORIGIN,
		...(hasEcwid ? PRECONNECT_ECWID : []),
		...(hasYoutube ? PRECONNECT_YOUTUBE : []),
		...(hasVimeo ? PRECONNECT_VIMEO : []),
		...(hasMap ? PRECONNECT_MAP : []),
		...(hasInstagram ? PRECONNECT_INSTAGRAM : []),
	];

	return preconnectLinks.map((href) => ({
		type: 'element',
		tagName: 'link',
		properties: {
			rel: 'preconnect',
			href,
			[ELEMENT_DATA_ATTRIBUTE]: href,
		},
	}));
};

const getGenericHeadElements = (headData) => {
	const {
		title,
		description,
		siteName,
		ogImageAlt,
		ogImageUrl,
		faviconUrl,
		redirectToCanonical,
		canonicalUrl,
		noindex,
	} = headData;

	const headElements = {
		title: {
			tagName: 'title',
			children: [
				{
					type: 'text',
					value: title,
				},
			],
		},
		'og:title': {
			tagName: 'meta',
			properties: {
				content: title,
				property: 'og:title',
			},
		},
		'og:type': {
			tagName: 'meta',
			properties: {
				content: 'website',
				property: 'og:type',
			},
		},
		'og:url': {
			tagName: 'meta',
			properties: {
				content: canonicalUrl,
				property: 'og:url',
			},
		},
		'twitter:card': {
			tagName: 'meta',
			properties: {
				name: 'twitter:card',
				content: 'summary_large_image',
			},
		},
		'twitter:title': {
			tagName: 'meta',
			properties: {
				name: 'twitter:title',
				content: title,
			},
		},
		favicon: {
			tagName: 'link',
			properties: {
				rel: 'icon',
				href: faviconUrl,
			},
		},
		'apple-touch-icon': {
			tagName: 'link',
			properties: {
				rel: 'apple-touch-icon',
				href: faviconUrl,
			},
		},
	};

	if (siteName) {
		headElements['og:site_name'] = {
			tagName: 'meta',
			properties: {
				content: siteName,
			},
		};
	}

	if (description) {
		headElements.description = {
			tagName: 'meta',
			properties: {
				name: 'description',
				content: description,
			},
		};

		headElements['og:description'] = {
			tagName: 'meta',
			properties: {
				content: description,
				property: 'og:description',
			},
		};

		headElements['twitter:description'] = {
			tagName: 'meta',
			properties: {
				name: 'twitter:description',
				content: description,
			},
		};
	}

	if (ogImageUrl) {
		headElements['og:image'] = {
			tagName: 'meta',
			properties: {
				content: bustImageUrlCache(ogImageUrl),
				property: 'og:image',
			},
		};
		headElements['og:image:alt'] = {
			tagName: 'meta',
			properties: {
				content: ogImageAlt,
			},
		};
		headElements['twitter:image'] = {
			tagName: 'meta',
			properties: {
				name: 'twitter:image',
				content: bustImageUrlCache(ogImageUrl),
			},
		};
		headElements['twitter:image:alt'] = {
			// twitter:image:alt should be always last as it breaks other metas on iMessage
			tagName: 'meta',
			properties: {
				name: 'twitter:image:alt',
				content: ogImageAlt,
			},
		};
	}

	if (noindex) {
		headElements.robots = {
			tagName: 'meta',
			properties: {
				name: 'robots',
				content: 'noindex',
			},
		};
	}

	// We redirect to the canonical URL of the page if site is accessed NOT via
	// zyro subdomain and domain does not match the hostname
	// (for ex., domain = 'domain.com', but site is accessed via 'www.domain.com' -> we redirect to 'domain.com')
	// As well, we do not generate the redirect during prerender - so prerender would work properly
	// (for ex., custom domain starts to redirect to zyro subdomain)
	if (redirectToCanonical) {
		headElements['http-equiv'] = {
			tagName: 'meta',
			properties: {
				'http-equiv': 'refresh',
				content: `0; url=${canonicalUrl}`,
			},
		};
	}

	return Object.entries(headElements).map(([
		elementKey,
		{
			tagName,
			properties,
			children,
		},
	]) => ({
		type: 'element',
		tagName,
		properties: {
			...properties,
			[ELEMENT_DATA_ATTRIBUTE]: elementKey,
		},
		children: children ?? [],
	}));
};

const getGoogleFontLinkElements = (href) => {
	const googleFontsLinkElementData = {
		'gstatic-preconnect': {
			tagName: 'link',
			properties: {
				href: import.meta.env.VITE_CDN_ORIGIN,
				rel: 'preconnect',
				crossorigin: true,
			},
		},
		'google-fonts-preload': {
			tagName: 'link',
			properties: {
				href,
				rel: 'preload',
				as: 'style',
			},
		},
		'google-fonts-stylesheet': {
			tagName: 'link',
			properties: {
				href,
				rel: 'stylesheet',
				referrerpolicy: 'no-referrer',
			},
		},
	};

	return Object.entries(googleFontsLinkElementData).map(([
		elementKey,
		{
			tagName,
			properties,
		},
	]) => ({
		type: 'element',
		tagName,
		properties: {
			...properties,
			[ELEMENT_DATA_ATTRIBUTE]: elementKey,
		},
	}));
};

export const addDocumentElements = ({
	siteData,
	path,
}) => {
	let googleFontLinkElements = [];

	const headData = getHeadData({
		siteData,
		path,
	});

	if (siteData?.fonts) {
		const customFonts = siteData.fonts.filter(({ type }) => type === FONT_TYPE_CUSTOM) || [];

		if (customFonts.length > 0) {
			addCustomFontsFontFaces({
				customFonts,
				siteId: siteData.siteId,
			});
		}

		const usedGoogleFonts = siteData.fonts
			.filter(({
				type,
				weights,
			}) => type === FONT_TYPE_GOOGLE && weights.length)
			.map((usedGoogleFont) => ({
				...usedGoogleFont,
				weights: [...new Set([...usedGoogleFont.weights.map((weight) => weight.toString())])],
			})) || [];

		if (usedGoogleFonts.length > 0) {
			const googleFontFacesHref = constructGoogleFontsHref({
				googleFonts: usedGoogleFonts,
				origin: import.meta.env.VITE_CDN_ORIGIN,
			});

			googleFontLinkElements = getGoogleFontLinkElements(googleFontFacesHref);
		}
	}

	const headElements = [
		...getPreconnectLinkElements(siteData),
		...getGenericHeadElements(headData),
		...googleFontLinkElements,
		...getIntegrationElements({
			siteMeta: siteData.meta,
			elementDataAttribute: ELEMENT_DATA_ATTRIBUTE,
		}),
	];

	addElementsToDom(headElements);

	// custom elements should be added to </head> and </body> only during preview or in SPA mode
	// otherwise, scripts could be injected and executed multiple times
	if (!isAppPrerendering && !isAppHydrating && siteData.meta?.customMeta) {
		addCustomElements(siteData.meta.customMeta);
	}

	document.documentElement.setAttribute('lang', headData.lang);
};
