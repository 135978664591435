<template>
	<div>
		<Integrations :site-meta="siteMeta" />
		<RouterView />
		<Lightbox v-if="isLightboxOpen" />
	</div>
</template>

<script>
import { useStore } from 'vuex';
import Lightbox from '@zyro-inc/site-modules/components/lightbox/Lightbox.vue';
import { useLightbox } from '@zyro-inc/site-modules/components/lightbox/useLightbox';
import Integrations from '@/components/metas/integrations/Integrations.vue';

import {
	defineComponent,
	computed,
} from 'vue';

export default defineComponent({
	components: {
		Lightbox,
		Integrations,
	},

	setup() {
		const { state } = useStore();

		const siteMeta = computed(() => state.website?.meta ?? {});
		const { isLightboxOpen } = useLightbox();

		return {
			siteMeta,
			isLightboxOpen,
		};
	},
});
</script>
