<template>
	<a
		v-if="src && href"
		class="media-wrapper"
		title="Open instagram page of this image"
		:href="href"
		rel="noopener"
		target="_blank"
	>
		<GridInstagramFeedMediaItemFigure
			:src="src"
			:alt="alt"
			:poster="poster"
		/>
	</a>
	<div
		v-else-if="src && !href"
		class="media-wrapper"
	>
		<GridInstagramFeedMediaItemFigure
			:src="src"
			:alt="alt"
			:poster="poster"
		/>
	</div>
	<div
		v-else
		class="placeholder"
	/>
</template>

<script>
import GridInstagramFeedMediaItemFigure from './GridInstagramFeedMediaItemFigure.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		GridInstagramFeedMediaItemFigure,
	},

	props: {
		src: {
			type: String,
			default: null,
		},
		alt: {
			type: String,
			default: null,
		},
		href: {
			type: String,
			default: null,
		},
		poster: {
			type: String,
			default: null,
		},
	},
});
</script>

<style lang="scss" scoped>
@import "./common";

.media-wrapper {
	position: relative;

	&:first-child {
		grid-area: 1/1/1/1;
	}
}

.placeholder:last-child {
	@include instagram-placeholder;

	display: none;
}
</style>
