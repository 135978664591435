<template>
	<GridButton
		v-qa="'button-ecommerce-checkout'"
		tag-name="button"
		:content="content"
		:type="type"
		:is-disabled="!canAddToCart(productId, productVariantId)"
		:is-loading="isLoading"
		:font-size-mobile="fontSizeMobile"
		:font-size-desktop="fontSizeDesktop"
		:font-family="fontFamily"
		:font-weight="fontWeight"
		:border-radius="borderRadius"
		:background-color="backgroundColor"
		:font-color="fontColor"
		:border-color="borderColor"
		:background-color-hover="backgroundColorHover"
		:font-color-hover="fontColorHover"
		:border-color-hover="borderColorHover"
		@click="handleClick"
	/>
</template>

<script>
import {
	mapActions,
	mapGetters,
	mapState,
} from 'vuex';

import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import { useGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridButton';
import { useEcommerceGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridEcommerceButton';
import { isInPreviewIframe } from '@zyro-inc/site-modules/utils/isInPreviewIframe';

import { useEcommerceModal } from '@/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@/components/ecommerce/useEcommerce';
import { PRODUCT_TYPE_BOOKING } from '@zyro-inc/site-modules/constants/ecommerce';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'GridEcommerceButtonProviderUser',

	components: {
		GridButton,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
		isCartVisible: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const { initiateCheckout } = useEcommerce(props);
		const { openEcommerceModal } = useEcommerceModal(props);
		const {
			content,
			type,
			fontSizeMobile,
			fontSizeDesktop,
			fontFamily,
			fontWeight,
			borderRadius,
			backgroundColor,
			fontColor,
			borderColor,
			backgroundColorHover,
			fontColorHover,
			borderColorHover,
		} = useGridButton(props);

		const {
			productId,
			productVariantId,
		} = useEcommerceGridButton(props);

		return {
			openEcommerceModal,
			productId,
			productVariantId,
			content,
			type,
			fontSizeMobile,
			fontSizeDesktop,
			fontFamily,
			fontWeight,
			borderRadius,
			backgroundColor,
			fontColor,
			borderColor,
			backgroundColorHover,
			fontColorHover,
			borderColorHover,
			initiateCheckout,
		};
	},

	data() {
		return {
			isLoading: false,
		};
	},

	computed: {
		...mapState('ecommerce', [
			'isShoppingCartOpen',
			'products',
			'shoppingCartItems',
		]),
		...mapGetters('ecommerce', ['canAddToCart']),
	},

	methods: {
		...mapActions('ecommerce', [
			'setShoppingCartOpen',
			'setShoppingCartItems',
			'setSelectedBookingId',
		]),
		async handleClick() {
			const product = this.products.find((item) => item.id === this.productId);

			if (product?.type.value === PRODUCT_TYPE_BOOKING) {
				this.setSelectedBookingId(this.productId);
				this.openEcommerceModal('EcommerceBookingEventSelect');

				return;
			}

			if (isInPreviewIframe) {
				this.openEcommerceModal('EcommerceMessageButtonDisabled');

				return;
			}

			const variant = product?.variants.find((item) => item.id === this.productVariantId);
			const productToAdd = {
				...product,
				variants: [variant],
			};

			if (this.isCartVisible) {
				this.setShoppingCartItems([
					...this.shoppingCartItems,
					productToAdd,
				]);

				this.manageCartOpenState();
			} else {
				this.isLoading = true;

				await this.initiateCheckout([productToAdd]).then(() => {
					this.isLoading = false;
				});
			}
		},
		manageCartOpenState() {
			if (this.isShoppingCartOpen) {
				return;
			}

			this.setShoppingCartOpen(true);
		},
	},
});
</script>
