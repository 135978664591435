<template>
	<div
		v-qa="`ecommerce-modal-checkout-${dataQaModalType}`"
		class="payment-info"
	>
		<div class="payment-info__content">
			<h5 class="payment-info__title">
				{{ title }}
			</h5>
			<p class="payment-info__text body-large">
				{{ text }}
			</p>
			<GridButton
				class="payment-info__button"
				:content="buttonText"
				type="primary"
				@click="$emit('handle-close')"
			/>
		</div>
	</div>
</template>

<script>
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'EcommerceModalContent',

	components: {
		GridButton,
	},

	props: {
		title: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		buttonText: {
			type: String,
			required: true,
		},
		dataQaModalType: {
			type: String,
			default: 'success',
		},
	},
});
</script>

<style lang="scss" scoped>
@import "./styles";
</style>
